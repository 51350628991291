import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import styled from "@emotion/styled"

import Layout from "../components/Layout"
import Head from "../components/Head"

const Content = styled.div`
  display: grid;
  margin-top: 81.5px;
  grid-template-columns: 1fr;
  grid-gap: 30px;
  @media (min-width: 480px) and (max-width: 748.9px) {
    grid-template-columns: repeat(2, 1fr);
  }
`
const CardTop = styled(Link)`
  background: #69cdcd;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  h1 {
    color: white;
    font-family: "Montserrat", sans-serif;
    font-size: 260px;
    font-weight: 800;
    margin: 0;
  }
  @media (min-width: 480px) and (max-width: 748.9px) {
    h1 {
      margin: 20px auto;
      font-size: 100px;
    }
  }
  @media (min-width: 749px) {
    width: 350px;
    height: 400px;
    h1 {
      font-size: 200px;
    }
  }
  @media (min-width: 970px) {
    height: 500px;
    width: 450px;
    h1 {
      font-size: 260px;
    }
  }
`

const Card = styled.div`
  &:nth-of-type(1) > ${CardTop} {
    position: relative;
    &::after {
      content: "NEW";
      background: #e45865;
      color: white;
      font-family: "Montserrat", sans-serif;
      font-size: 10px;
      font-weight: 700;
      padding: 4px 15px;
      position: absolute;
      top: 3%;
      left: 90%;
      @media (max-width: 479.9px) {
        left: 89%;
      }
      @media (min-width: 480px) and (max-width: 748.9px) {
        left: 85%;
      }
    }
  }
  &:nth-of-type(2) > ${CardTop}, &:nth-of-type(5) > ${CardTop} {
    background: #ffd4c4;
  }
  &:nth-of-type(3) > ${CardTop}, &:last-of-type > ${CardTop} {
    background: #1c528a;
  }
  &:nth-of-type(4) > ${CardTop} {
    background: #e45865;
  }
  &:last-of-type {
    margin-bottom: 40px;
  }
  @media (min-width: 749px) {
    &:nth-of-type(even) {
      margin-left: auto;
    }
    &:nth-of-type(2) > ${CardTop} {
      height: 300px;
    }
    &:last-of-type {
      grid-column: 2 / 2;
      margin-left: auto;
      & > ${CardTop} {
        // background: #98dede;
        height: 300px;
      }
    }
    &:nth-of-type(4) {
      margin-top: -100px;
    }
    &:nth-of-type(5) {
      margin-top: 100px;
    }
  }
`

const Button = styled.a`
  border: 2px solid #1c528a;
  background: white;
  font-family: "Playfair Display", serif;
  text-transform: uppercase;
  font-size: 15px;
  color: #1c528a;
  font-weight: 700;
  padding: 10px 25px;
  cursor: pointer;
  position: relative;
  top: -20px;
  display: inline-block;
  text-decoration: none;
  background: linear-gradient(to right, #1c528a 50%, white 50%);
  background-size: 300% 100%;
  background-position: right bottom;
  transition: all 0.75s ease-out;
  &:hover {
    background-position: left bottom;
    color: white;
  }
`

const StyledCard = styled(Card)`
  & > ${CardTop} {
    transition: all 1.5s ease-in-out;
    &:hover {
      h1 {
        opacity: 0;
      }
      background: url(${props => props.background}) no-repeat right center;
      background-size: cover;
    }
  }
`

const Projects = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulProjects(sort: { fields: publishedDate, order: DESC }) {
        edges {
          node {
            title
            slug
            techTitle
            hoverImages {
              fluid {
                src
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <Head title="Projects" />
      <h1 className="title">Latest Projects</h1>
      <Content>
        {data.allContentfulProjects.edges.map(project => {
          const { title, techTitle, slug, hoverImages } = project.node

          return (
            <StyledCard
              key={title}
              data-sal="slide-up"
              data-sal-duration="1000"
              data-sal-easing="ease-out-back"
              background={hoverImages.fluid.src}
            >
              <CardTop to={`/projects/${slug}`}>
                <h1>{title.charAt(0)}</h1>
              </CardTop>
              <Link to={`/projects/${slug}`} className="project-tags">
                <h1>{title}</h1>
                <p>{techTitle}</p>
              </Link>
            </StyledCard>
          )
        })}
      </Content>

      <Button
        href="https://github.com/urlDev"
        target="_blank"
        rel="noopener noreferrer"
      >
        view all
      </Button>
    </Layout>
  )
}

export default Projects

// &:nth-child(1) > ${CardTop}, &:nth-child(2) > ${CardTop}, &:nth-child(3) > ${CardTop}, &:nth-child(4) > ${CardTop}, &:nth-child(5) > ${CardTop}
